@use 'modules/colors.scss' as colors;

html {
	box-sizing: border-box;
	font-size: 62.5%; /* 1rem = 10px */
	line-height: 1.15;
	text-size-adjust: 100%;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	margin: 0;
	word-wrap: break-word;
}

html,
body,
#root {
	height: 100%;
	min-height: 100%;
	width: 100%;
}

img {
	max-width: 100%;
}

@media (hover: hover) and (pointer: fine) {
	html {
		scrollbar-color: rgb(0 0 0 / 60%);
		scrollbar-width: thin;
	}

	::-webkit-scrollbar {
		border-radius: 0.5rem;
		height: 0.8rem;
		width: 0.8rem;
	}

	::-webkit-scrollbar-track {
		background-color: transparent;
	}

	::-webkit-scrollbar-thumb {
		background-color: transparent;
		border-radius: 0.5rem;
	}

	body::-webkit-scrollbar-track {
		background-color: rgb(0 0 0 / 20%);
	}

	body::-webkit-scrollbar-thumb {
		background-color: colors.$primary;
	}

	:hover::-webkit-scrollbar-thumb {
		background-color: rgb(0 0 0 / 60%);
	}

}
