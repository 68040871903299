@use 'assets/css/modules/spacings.scss';
@use 'assets/css/modules/colors.scss' as colors;
@use 'assets/css/modules/header' as header;
@use 'assets/css/modules/navigation' as navigation;

.main-layout {
	display: flex;
	padding-top: header.$height;
}

.main-header {
	align-items: center;
	background: colors.$surface;
	display: flex;
	justify-content: space-between;
	height: header.$height;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 800;
}

.header-logo {
	display: flex;
	margin-right: auto;
	max-height: 100%;
	max-width: navigation.$width;
	padding: var(--spacing-horizontal);
	width: 100%;
}

.header-logo img {
	max-height: 100%;
}

.header-content {
	align-items: center;
	display: flex;
	flex-flow: row nowrap;
	gap: var(--spacing-horizontal);
	justify-content: space-between;
	margin:0 0 0 auto;
	padding: var(--spacing-horizontal);
	position: relative;
}

.main-content {
	min-height: 100%;
	padding: var(--spacing-horizontal);
	position: relative;
	width: 100%;
}

.main-nav {
	background: colors.$surface;
	border-bottom: 1px solid rgb(0 0 0 / 5%);
	border-top: 1px solid rgb(0 0 0 / 5%);
	bottom: 0;
	display: block;
	height: calc(100% - header.$height);
	left: 0;
	max-width: navigation.$width;
	overflow: auto;
	padding: var(--spacing-horizontal);
	position: fixed;
	top: header.$height;
	transform: translateX(-100%);
	transition: transform 0.3s;
	width: 100%;
	z-index: 700;
}

.is-nav-active .main-nav {
	transform: translateX(0);
}

.main-nav .nav-group {
	margin-bottom: var(--spacing-xs);
}

.mobile-menu .content-overlay {
	background: rgba($color: #000, $alpha: 75%);
	inset: 0;
	opacity: 0;
	position: fixed;
	visibility: hidden;
	transition: opacity 0.3s, visibility 0.3s;
	z-index: 90;
}

.mobile-menu.is-nav-active .content-overlay {
	opacity: 1;
	visibility: visible;
}

@media (min-width: navigation.$desktopBreakpoint) {
	.desktop-menu .content-overlay {
		display: none;		
	}

	.desktop-menu .main-nav {
		transform: translateX(0);
	}

	.desktop-menu .main-content {
		margin-left: navigation.$width;
	}
}
