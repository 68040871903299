:root {
    --spacing-xs: 8px;
    --spacing-sm: 10px;
    --spacing-md: 15px;
    --spacing-lg: 20px;
    --spacing-xl: 25px;
    --spacing-horizontal: var(--spacing-md);
    --spacing-vertical: var(--spacing-lg);
}


@media (width >= 1024px) {
    :root {
        --spacing-xs: 10px;
        --spacing-sm: 15px;
        --spacing-md: 20px;
        --spacing-lg: 25px;
        --spacing-xl: 30px;
        --spacing-horizontal: var(--spacing-sm);
        --spacing-vertical: var(--spacing-lg);
    }
}

@media (width >= 1266) {
    :root {
        --spacing-xs: 15px;
        --spacing-sm: 20px;
        --spacing-md: 25px;
        --spacing-lg: 30px;
        --spacing-xl: 40px;
        --spacing-horizontal: var(--spacing-sm);
        --spacing-vertical: var(--spacing-lg);
    }
}

@media (width >= 1440) {
    :root {
        --spacing-xs: 15px;
        --spacing-sm: 25px;
        --spacing-md: 30px;
        --spacing-lg: 40px;
        --spacing-xl: 50px;
        --spacing-horizontal: var(--spacing-md);
        --spacing-vertical: var(--spacing-lg);
    }
}
